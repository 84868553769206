<template>
  <section>
    <sm_header :title='title' :smHleftEvent='closeWebview'></sm_header>
    <div class="loadmore_container" ref="wrapper" :style="{ height: wrapperHeight + 'px' }">
      <i-scrollBox class="opOrderListScroll" :loadType="''">
        <template v-slot:scrollBox>
          <ul class="meeting_personnel" v-if="supplierType=='V12'">
            <li class="personnelItem" v-if="serviceChargeList.itemList.length>0">
              <h3 class="personnel_type">{{serviceChargeList.costType}}</h3>
              <div class="personnel_info" v-for="(serviceCharge,index) in serviceChargeList.itemList" :key='index'>
                <div class="info_inner">
                  <h4>{{serviceCharge.type}}<span v-if="serviceCharge.isLoss">--损失费</span> </h4>
                  <p>人数：{{serviceCharge.qty}}</p>
                  <p>天数：{{serviceCharge.qtyExt}}</p>
                  <p v-if='serviceCharge.type=="现场人员"'>日期：{{serviceCharge.startDate|formatDate('yyyy/MM/dd')}} ~ {{serviceCharge.endDate|formatDate('yyyy/MM/dd')}}</p>
                  <p>备注：{{serviceCharge.descr||'暂无备注'}}</p>
                </div>
                <div class="amount">
                  <span class="color_FB304D">总金额 {{serviceCharge.amount|currencyFormatter}}</span>
                </div>
              </div>
            </li>
            <li class="personnelItem margin_top10 maring_bottom54" v-if="disbursementsFeesList.itemList.length>0">
              <h3 class="personnel_type">{{disbursementsFeesList.costType}}</h3>
              <div class='disbursement' v-for="(disbursementsFees,index) in disbursementsFeesList.itemList" :key='index'>
                <div class="personnel_info border_bottom">
                  <label class="color3">{{disbursementsFees.costType}}</label> <span class="color_FB304D">金额 {{disbursementsFees.amount|currencyFormatter}}</span>
                </div>
                <p style='font-size:.14rem;'>备注：{{disbursementsFees.descr||'暂无备注'}}</p>
              </div>
              <iFinderFrame v-if="filterData.processId && filterData.supplierId" :uploader-settings="{filterData: filterData}" readOnly="true"></iFinderFrame>
            </li>
          </ul>
        </template>
      </i-scrollBox>
      <div class="sm_footer_box">
        <div class="total">
          <p class="moneyNumber" v-if='supplierType=="V12"'>{{sum|currencyFormatter}}</p>
          <p class="moneyText color9">结算金额</p>
        </div>
        <div class="btnGrounp">
                <span class="footerBtn" @click='onceAgain(btn)' v-for="( btn, index) in btnList" :key='index' :style="{background:btn.itsActData.color}">
                    {{btn.actName}}
                </span>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import iFinderFrame from '@/components/iFinderFrame'
import iScrollBox from "@/components/iScrollBox/iScrollBox";
import Prompt from '@/components/prompt/plugin';
import generalBridge from "@/plugin/generalBridge.js";

export default {
  name: 'otherSupplier',
  components: {
    iFinderFrame,
    iScrollBox
  },
  data () {
    return {
      title: '上会服务人员服务费',
      supplierType:'V12',
      sum: null, // V12 总金额
      serviceChargeList:{costType:'上会服务人员服务费',itemList:[]}, // V12 服务费列表
      disbursementsFeesList:{costType:'上会服务人员垫付费',itemList:[]}, // V12 垫付费列表
      info:{itsSupplierExpenses:[{settleAmount:null}],supplierId:''}, // V6 总金额
      btnList:[], // 底部按钮列表
      wrapperHeight: 0,
      filterData:{
        processId:'',
        supplierId:''
      }
    }
  },
  mounted() {
    this.wrapperHeight = (document.documentElement.clientHeight || window.innerHeight) - this.$refs.wrapper.getBoundingClientRect().top;
  },
  created() {
    this.initPage()
  },
  methods: {
    // 返回按钮
    closeWebview() {
      generalBridge.propy.call(this)
    },
    initPage () {
      this.supplierType=this.$SmartStorage.get('supplierType')?this.$SmartStorage.get('supplierType'):'V12'
      switch(this.supplierType) {
        case "V12":
          this.title = '上会服务人员明细';
          this.QueryGroup()
          this.getConfirmTask()
          break;
        default:
          this.title = '其它项目明细';
      }
    },
    async QueryGroup() { // 获取上会服务人员
      let params = {
        "proposalId": this.$SmartStorage.get('proposalId') || '',
      }
      let res = await this.$service.QueryGroup(params)
      if (res.success) {
        this.sum = res.content.sum?res.content.sum:0
        this.serviceChargeList.itemList = res.content.serveExpenses
        this.disbursementsFeesList.itemList = res.content.advanceExpenses
        this.filterData.supplierId = res.content.supplierId;
      }
    },
    async getConfirmTask() { // 获取信息
      let params = {
        "proposalId": this.$SmartStorage.get('proposalId') || '',
        "NodeCode": this.$SmartStorage.get('supplierType')=='V12'?'ConfirmHhk':'ConfirmCtrip',
        "supplierType": this.$SmartStorage.get('supplierType')?this.$SmartStorage.get('supplierType'):'V12'
      }
      let res = await this.$service.GetConfirmTask(params)
      if(res.success) {
        this.info = res.content
        this.filterData.processId = res.content.proposalId
        if(res.content.taskStatus!=1) {
          this.QueryTaskActions(res.content.taskId)
        }
      }
    },
    async QueryTaskActions(taskId) { // 获取按钮
      let params = {
        taskId: taskId
      };
      let res = await this.$service.QueryTaskActions(params)
      this.btnList = res.content;
    },
    onceAgain(btn) { // 二次提示
      if(btn.actName=="退回") {
        Prompt.show({
          title: "请输入退回原因",
          onConfirm: (val) => {
            this.ProcessTask(btn,val)
          }
        })
      }else{
        let msg = '是否确认进行' + btn.actName+'操作'
        this.$dialog.confirm({
          message: msg,
        }).then(() => {
          this.ProcessTask(btn)
            })
            .catch(() => {
              // on cancel
            });
      }
    },
    async ProcessTask(btn,value) { // footerBtn==>流程接口ProcessTask
      let params = {
        taskId: this.info.taskId,
        nodeId: this.info.nodeId,
        actionId: btn.actionId,
        processId: this.info.proposalId,
      };
      switch(btn.actDirective){ // 通过不同btn.actDirective入参不同formData
        case 'task-reback':
          params.reason = value
          params.taskData = JSON.stringify({
            rebackReason: value
          }),
              params.formData = JSON.stringify({
                rebackReason: value
              })
          break;
      }
      let res = await this.$service.ProcessTask(params)
      if (res.success) {this.closeWebview()}
    }
  }
}
</script>
<style scoped lang="scss">
.opOrderListScroll {
  padding: .44rem .1rem .1rem;
}
.personnelItem{
  background: #fff;
  font-size: .16rem;
  color: #666;
  text-align: left;
  .personnel_type{
    font-size: .18rem;
    color: #2D82F0;
    box-sizing: border-box;
    padding: .15rem .15rem .15rem .15rem;
  }
  .personnel_info{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: .1rem .15rem .1rem .15rem;
    border-top: 1px solid #ccc;
    line-height: 1.5;
    .info_inner{
      h4{
        font-size: .16rem;
        color: #333;
      }
      p{
        font-size: .12rem;
      }
    }
  }
}
.color_FB304D {
  color: #fb304d;
}
.sm_footer_box {
  position: fixed;
  width: 100%;
  height: 0.48rem;
  bottom: 0;
  font-size: .18rem;
  z-index: 10;
  border-top: 1px solid #eee;
  background: #fff;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  .total{
    width: 25%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }
  .btnGrounp{
    width: 75%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    .footerBtn{
      width: 30%;
      text-align:center;
      font-size:0.16rem;
      height: 100%;
      line-height: .48rem;
      color: #fff;
    }
  }
}
</style>
